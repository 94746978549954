<template>
   <div>
      <template v-if="isLoading">
         <div class="h-screen text-center grid grid-cols-1 gap-4 place-content-center bg-indigo-900">
            <MazLoader/>
         </div>
      </template>
      <template v-else>
         <div class="bg-gray-100 h-screen flex antialiased">
            <aside class="">
               <UserSidebar />
            </aside>
            <main class="flex-1 flex flex-col min-h-screen h-full overflow-x-hidden overflow-y-auto">
               <UserNav />
               <main class="flex-grow">
                  <router-view />
               </main>
               <footer class="inset-x-0 bottom-0 w-full bg-white border-t border-gray-200 p-5 text-gray-500 font-medium 2xl:p-5">
                  <div class="mx-4">
                     <div class="flex flex-col-reverse md:flex-row text-center md:justify-center mx-auto max-w-full px-4 md:px-8 2xl:px-16">
                        <p class="text-xs lg:text-sm leading-6">
                        Copyright &copy; {{ new Date().getFullYear() }} <a class="font-bold text-indigo-900 transition-colors duration-200 ease-in-out hover:text-body" href="https://escrowlock.com">Escrowlock.</a> All rights reserved
                        </p>
                     </div>
                  </div>
               </footer>
            </main>
         </div>
      </template>
   </div>
</template>


<script>
import UserNav from '@/components/layouts/user/navbar.vue'
import UserSidebar from '@/components/layouts/user/sidebar.vue'
import { mapGetters } from 'vuex'
export default {
   name: "UserLayout",

   components: {
      UserNav,
      UserSidebar
   },

   data() {
      return {
         isLoading: false
      }
   },

   methods: {
      checkIsOnboarded() {
         if(this.user.account.is_onboarded == 'false') {
            this.$router.push( { name: 'UserOnboarding' } )
         }
      }
   },

   computed: {
      ...mapGetters('auth', ['user'])
   },

   mounted() {
      if(this.$store.getters['auth/user'] != '') {
         setTimeout(() => {
            this.isLoading = false
         }, 3000)
      }
      this.checkIsOnboarded()
   }
}
</script>

<style scoped>
.maz-loader__anim div::after {
   @apply bg-white
}
</style>