<template>
   <nav class="bg-white border-b border-gray-200">
      <div class="container px-10 py-4 mx-auto md:flex md:justify-between md:items-center">
         <div class="flex items-center justify-between">
            <router-link to="/" class="flex text-indigo-900">
               <div class="self-center">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                     <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                  </svg>
               </div>
               <div class="flex items-center justify-center flex-shrink-0 h-auto text-base xl:text-base text-heading font-semibold focus:outline-none">
                  <span class="text-xl pl-2 text-heading font-semibold focus:outline-none">EscrowLock</span>
               </div>
            </router-link>
               <!--div>
                  <router-link to="/" class="flex items-center justify-center flex-shrink-0 h-auto text-base xl:text-base text-heading font-semibold focus:outline-none">
                     <span class="text-base pl-2 text-heading font-semibold focus:outline-none">Welcome back, {{ user.account.first_name | ucwords }}</span>
                  </router-link>
               </div-->
               
               <!-- Mobile menu button -->
               <div class="flex md:hidden">
                  <button type="button" class="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600" aria-label="toggle menu">
                     <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                        <path fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"></path>
                     </svg>
                  </button>
               </div>
            </div>

         <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
         <div class="items-center">
            <div class="flex justify-center space-x-5 text-sm">
               <router-link to="/" class="relative flex space-x-1 text-gray-700 transition-colors mt-1 duration-200 transform hover:text-indigo-900">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 self-center" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  <span class="font-semibold">Help &amp; FAQs</span>
               </router-link>
               <div class="mt-1">
                  <OgCustomDropdown label="Support Tickets" :icon="supportIcon" :menuData="supportList" class="flex font-medium transition-colors duration-200 transform hover:text-indigo-900 md:mx-4 md:my-0"></OgCustomDropdown>
               </div>
            </div>
         </div>
      </div>
   </nav>
</template>


<script>
import OgCustomDropdown from '@/components/og-custom-dropdown'
import { mapGetters } from 'vuex'
export default {
   name: "UserNav",
   components: {
      OgCustomDropdown
   },
   data() {
      return {
         supportList: [
            { name: 'Create New Ticket', location: '/account/user/payout/' },
            { name: 'View My Tickets', location: '/account/user/payout/summery' }
         ],
         supportIcon: `<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 self-center" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                     <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z" />
                  </svg>`
      }
   },

   methods: {
      async logout() {

      }
   },

   computed: {
      ...mapGetters("auth", ["user"]),
   }
}
</script>