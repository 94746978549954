<template>
   <div class="">
      <div class="flex items-center justify-center flex-shrink-0 font-semibold focus:outline-none">
         <div class="relative inline-block text-left text-gray-800" v-on-clickaway="closeMenu">
            <button @click="isMenuOpen = !isMenuOpen" class="font-medium text-gray-600 hover:text-indigo-900 flex space-x-1" type="button" id="options-menu" aria-haspopup="true" aria-expanded="true">
               <span v-html="icon"></span>
               <span class="self-center pr-1 focus:outline-none">{{ label | ucwords }}</span>
               <svg v-if="!isMenuOpen" xmlns="http://www.w3.org/2000/svg" class="self-center h-5 w-5 transition duration-150" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
               </svg>
               <svg v-else xmlns="http://www.w3.org/2000/svg" class="self-center h-5 w-5 transition duration-150" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
               </svg>
            </button>
            <transition
               enter-active-class="transition ease-out duration-100"
               enter-class="transform opacity-0 scale-95"
               enter-to-class="transform opacity-100 scale-100"
               leave-active-class="transition ease-in duration-75"
               leave-class="transform opacity-100 scale-100"
               leave-to-class="transform opacity-0 scale-95"
            >
               <div v-if="isMenuOpen" class="origin-top-right absolute right-0 mt-1 w-56 rounded-md shadow-lg text-sm overflow-hidden border border-gray-50 z-20">
                  <div class="rounded-md bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                     <div class="font-medium" v-for="(l, index) in menuData" :key="index">
                        <router-link :to="l.location" class="px-5 py-3 text-sm hover:bg-indigo-900 hover:text-white flex items-center space-x-3">
                           <span>{{ l.name | ucwords }}</span>
                        </router-link>
                     </div>
                  </div>
               </div>
            </transition>
         </div>
      </div>
   </div>
</template>

<script>
import { directive as onClickaway } from 'vue-clickaway'

export default {
   name: "OgDropdown",

   props: {
    menuData: Array,
    label: String,
    icon: String,
  },

   data() {
      return {
         isMenuOpen: false
      }
   },

   methods: {
      closeMenu() {
         this.isMenuOpen = false
      }
   },

   directives: {
      onClickaway: onClickaway,
   },
}
</script>