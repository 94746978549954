<template>
   <div class="flex-shrink-0 relative z-20 hidden md:block w-64 h-screen p-5 py-8 bg-indigo-900 border-r border-gray-900">      
        <div class="text-center my-6">
           <img :src="user.account.avatar_url || require('@/assets/images/user.png')" class="rounded-full w-20 mx-auto" alt="Avatar" />
            <h2 class="text-lg font-semibold text-white text-center">{{ user.account.first_name | capitalize }} {{ user.account.last_name | capitalize}}</h2>
            <p class="text-gray-300 text-xs">{{ user.email }}</p>
        </div>
        <hr class="border-blue-900 border-t" />
        <div class="flex flex-col justify-between flex-1">
            <nav>
                <router-link :to="{ name: 'Dashboard' }" :class="$route.name == 'Dashboard' ? 'bg-indigo-700' : ''" class="flex items-center px-4 mt-4 py-2 text-gray-50 hover:bg-indigo-700 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="h-4 w-4">
                        <path fill="currentColor" d="M10,13H4a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V14A1,1,0,0,0,10,13ZM9,19H5V15H9ZM20,3H14a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V4A1,1,0,0,0,20,3ZM19,9H15V5h4Zm1,7H18V14a1,1,0,0,0-2,0v2H14a1,1,0,0,0,0,2h2v2a1,1,0,0,0,2,0V18h2a1,1,0,0,0,0-2ZM10,3H4A1,1,0,0,0,3,4v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V4A1,1,0,0,0,10,3ZM9,9H5V5H9Z"/>
                    </svg>
                    <span class="mx-4 font-normal text-sm">Dashboard</span>
                </router-link>
            
                <router-link :to="{ name: 'Transactions' }" 
                    :class="$route.name == 'Transactions' || 
                        $route.name == 'SelectedTransaction' || 
                        $route.name == 'CreateTransaction' ? 'bg-indigo-700' : ''" class="flex items-center px-4 mt-4 py-2 text-gray-50 hover:bg-indigo-700 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" data-name="Layer 1" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M20,2H10A3,3,0,0,0,7,5v7a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V5A3,3,0,0,0,20,2Zm1,10a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1H20a1,1,0,0,1,1,1ZM17.5,8a1.49,1.49,0,0,0-1,.39,1.5,1.5,0,1,0,0,2.22A1.5,1.5,0,1,0,17.5,8ZM16,17a1,1,0,0,0-1,1v1a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V15H4a1,1,0,0,0,0-2H3V12a1,1,0,0,1,1-1A1,1,0,0,0,4,9a3,3,0,0,0-3,3v7a3,3,0,0,0,3,3H14a3,3,0,0,0,3-3V18A1,1,0,0,0,16,17ZM6,18H7a1,1,0,0,0,0-2H6a1,1,0,0,0,0,2Z"/>
                    </svg>
                    <span class="mx-4 font-normal text-sm">Transactions</span>
                </router-link>
            
                <router-link :to="{ name: 'Wallet' }" :class="$route.name == 'Wallet' ? 'bg-indigo-700' : ''" class="flex items-center px-4 mt-4 py-2 text-gray-50 hover:bg-indigo-700 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-4 w-4">
                        <path fill="currentColor" d="M19,7H18V6a3,3,0,0,0-3-3H5A3,3,0,0,0,2,6H2V18a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V10A3,3,0,0,0,19,7ZM5,5H15a1,1,0,0,1,1,1V7H5A1,1,0,0,1,5,5ZM20,15H19a1,1,0,0,1,0-2h1Zm0-4H19a3,3,0,0,0,0,6h1v1a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V8.83A3,3,0,0,0,5,9H19a1,1,0,0,1,1,1Z"/>
                    </svg>
                    <span class="mx-4 font-normal text-sm">Wallet</span>
                </router-link>
            
                <router-link :to="{ name: 'Profile' }" :class="$route.name == 'Profile' || $route.name == 'Banking' || $route.name == 'Contacts' ? 'bg-indigo-700' : ''" class="flex items-center px-4 mt-4 py-2 text-gray-50 hover:bg-indigo-700 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                    <span class="mx-4 font-normal text-sm">Profile</span>
                </router-link>
            
                <router-link :to="{ name: 'Security' }" 
                    :class="$route.name == 'Security' || 
                        $route.name == 'ChangePassword' || 
                        $route.name == 'TwoFactor' || 
                        $route.name == 'AntiPhishing' || 
                        $route.name == 'SecurityQuestions' || 
                        $route.name == 'FingerPrintAuth' ? 'bg-indigo-700' : ''" class="flex items-center px-4 mt-4 py-2 text-gray-50 hover:bg-indigo-700 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4   " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                    </svg>
                    <span class="mx-4 font-normal text-sm">Security</span>
                </router-link>
            
            
                <router-link :to="{ name: 'Affiliate' }" :class="$route.name == 'Affiliate' ? 'bg-indigo-700' : ''" class="flex items-center px-4 mt-4 py-2 text-gray-50 hover:bg-indigo-700 rounded">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z" />
                    </svg>
                    <span class="mx-4 font-normal text-sm">Affiliate</span>
                </router-link>

                <router-link :to="{ name: 'Support' }" :class="$route.name == 'Support' ? 'bg-indigo-700' : ''" class="flex items-center px-4 mt-4 py-2 text-gray-50 hover:bg-indigo-700 rounded">
                    <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 5V7M15 11V13M15 17V19M5 5C3.89543 5 3 5.89543 3 7V10C4.10457 10 5 10.8954 5 12C5 13.1046 4.10457 14 3 14V17C3 18.1046 3.89543 19 5 19H19C20.1046 19 21 18.1046 21 17V14C19.8954 14 19 13.1046 19 12C19 10.8954 19.8954 10 21 10V7C21 5.89543 20.1046 5 19 5H5Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span class="mx-4 font-normal text-sm">Support</span>
                </router-link>
                
               <div @click="logout()" class="flex items-center px-4 mt-4 py-2 text-gray-50 hover:bg-indigo-700 rounded cursor-pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="h-4 w-4">
                     <path fill="currentColor" d="M12.59,13l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H3a1,1,0,0,0,0,2ZM12,2A10,10,0,0,0,3,7.55a1,1,0,0,0,1.8.9A8,8,0,1,1,12,20a7.93,7.93,0,0,1-7.16-4.45,1,1,0,0,0-1.8.9A10,10,0,1,0,12,2Z"/>
                  </svg>
                  <span class="mx-4 text-sm">Logout</span>
               </div>
            </nav>
        </div>
   </div>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
   name: "UserSidebar",

    data() {
        return {
            
        }
    },

    methods: {
        async logout() {
            try {
            let res = await this.$store.dispatch('auth/token_terminate')
            
            this.$toast.success(res.message)
            } catch (error) {
               this.$toast.error(error.response.message)
               if(error.response && error.response.status === 401) {
                  this.$store.commit('logout');
                  this.router.push('/auth/sign-in');
               }
            }
        }
   },

   computed: {
      ...mapGetters("auth", ["user"]),
   }
}
</script>